:root{
  
  --themeclr1: #a47831;
  --themeclr2: #394248;
}

.italic{
  font-family: 'Merriweather Sans', sans-serif;
}

div#root {
  max-height: 100vh;
}

main{
  margin-top: 81px;
}

h1, p {
  font-family: Lato;
}
header.main-header{
  
  color: #ffffff;
  
}

section#Booking {
  min-height: 101vh;
}
#About.page-header.proj-det.bg-img {
  min-height: 100vh;
  padding-bottom: 100px;
}

.veriFy{
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(255,255,255,.7);
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.deletebtn{
  position: relative;
}

.deletebtn:hover:after {
  content: "Remove";
  position: absolute;
  top: 20%;
  right: 111%;
  background: #333;
  font-size: 10px;
  padding: 3px 5px;
}

.veriFy .close{
  
  position: absolute;
  right: 30px;
  top: 100px;
  border: none;
  background: #d61f24;
  border-radius: 50%;
  padding: 5px 10px 5px 11px;
  color: #fff;
  font-weight: bold;
}
.img_responsive{
  max-width:100%;
}
.verification{
  padding: 15px;
  background: #ffffff;
  -webkit-box-shadow: 0 0 8px 1px #888888;
box-shadow: 0 0 8px 1px #888888;
max-width: 360px;
overflow: auto;
max-height: 95%;
}
.validate{
  background: var(--themeclr1);
  color: #ffffff;
}
.validate:hover{
  color: var(--themeclr1);
  background: #ffffff;
  border-color: var(--themeclr1);
}
.btn-light-transparent{
  background: none;
  border: none;
  color: #ffffff;
}

.btn-light-transparent:hover{
  background: #ffffff;
  border: none;
  color: var(--themeclr1);
}

.timeline .rb-container ul.rb > li .rb-content .is-male{
  color: #139ac3;
}

.timeline .rb-container ul.rb > li .rb-content .is-female{
  color: #e04466;
}

.timeline .rb-container ul.rb > li:hover .rb-content .is-male{
  color: #ccf3ff;
}

.timeline .rb-container ul.rb > li:hover .rb-content .is-female{
  color: #fcb1c2;
}

.timeline .rb-container ul.rb > li .rb-content .is-other, .timeline .rb-container ul.rb > li .rb-content .text-other{
  color: #e04466;
}
.timeline .rb-container ul.rb > li:hover .rb-content .is-other, .timeline .rb-container ul.rb > li:hover .rb-content .text-other{
  color: #c31313;
  background: linear-gradient(to right, #ef5350, #f48fb1, #2196f3, #26c6da, #a8b610, #f9a825, #ff5722);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.timeline .rb-container ul.rb > li .rb-content .text-india{
  color: #004070;
}
.timeline .rb-container ul.rb > li:hover .rb-content .text-india{
  color: var(--themeclr1);
  background: linear-gradient(to right, #e78607, #ffffff, #43a047);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.timeline .rb-container ul.rb > li .rb-content .is-child{
 color: #a47831;
}

.timeline .rb-container ul.rb > li .rb-content .is-adult{
  color: #387203;
}
.timeline .rb-container ul.rb > li:hover .rb-content .is-child{
 color: #ffeccd;
}

.timeline .rb-container ul.rb > li:hover .rb-content .is-adult{
  color: #efffe0;
}
.progress-bar.bg-light {
  background-color: #d9d9d9!important;
  color: #262626;
}
progress{
  text-align: center;
  -webkit-appearance: none;
  border: none;
  margin: 15px 0;
  height: 5px;
  /* Set the progressbar to relative */
  position:relative;
  color: #ffffff;
}
progress:before {
  content: attr(data-label);
  font-size: 0.8em;
  vertical-align: 0;
  /*Position text over the progress bar */
  position:absolute;
  padding: 5px 0;
  left:0;
  right:0;
}
progress::-webkit-progress-bar {
  background-color: #c9c9c9;
}
progress::-webkit-progress-value {
  background-color: #004070;
}
progress::-moz-progress-bar {
  background-color: #004070;
}

pre.copier{
  position: relative;
  color: #034272;
  background: #d4b739;
}
pre.copier span {
  position: absolute;
  right: 0;
  top: 0;
  margin: 5px;
  padding: 2px 10px;
  font-size: 12px;
  text-transform: uppercase;
  background: #fff;
}
pre.copier span:hover{ 
  color: #fff;
  background: #a47831;
}
#mobile{
  
  border: 1px solid #a47831;
  color: #034272;
  font-weight: bold;
}
pre{
  border: 1px dashed #333333;
  
  margin: 5px;
  font-size: 18px;
  font-weight: bold;
  padding: 5px;
}

.form-switch label.form-check-label {
  margin-left: 1.5em;
}
.form-switch .form-check-input{
  margin-left:  -1.5em;
}


@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0,64,113,1) !important;
  z-index: 9999999;
}

.loader {
  top: 10%;
  width: 180px;
  height: 180px;
  border-radius: 100%;
  position: relative;
  margin: 0 auto;
  background-image: url(./assets/img/golden_logo.png);
  background-position: 30px 30px;
  background-size: 99px;
  background-repeat: no-repeat;
}

#loader-1:before, #loader-1:after {
  content: "";
  position: absolute;
  top: -10px;
  left: -10px;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border: 7px solid transparent;
  border-top-color: #d7bf55;
}

#loader-1:before {
  z-index: 100;
  animation: spin 2s infinite;
}

#loader-1:after {
  border: 7px solid #fafafa;
}
.topmenu{
  display: flex;
  grid-gap: 5px;
  margin: 0;
  padding: 0 10px;
  gap: 0px 15px;
  justify-content: center;
  align-items: center;
}
.row.person {
  padding: 10px;
  margin: 10px;
  border: 1px dashed;
}
.row.ticket-heading{
  padding: 10px;
  margin: 0 10px;
  border: 1px dashed;
  
  color: #d7bf55;
  background-color: #483f38;
}

.qrcode {
  background: #483f38;
  padding: 15px;
  color: #d7bf55;
}
ul.list-inline {
  display: flex;
  justify-content: space-evenly;
}
.text-right{
  text-align: right;
}
.text-center{
  text-align: center;
}

header.main-header .navigation{
  position: relative;
  z-index: 9;
  background: var(--themeclr1);
  
}

.second-header {
  position: fixed;
  z-index: 999;
  max-width: 300px;
  right: 72px;
  bottom: 10px;
  color:#fff;
}

.second-header .list-toggler{
  position: absolute;
  bottom: 100%;
  right: 0%;
  padding: 10px;
}

.second-header .list-toggler.closed {
  left: 100%;
  right: auto;
}

.second-header .frow {
  background: rgba(0,0,0,.7);
  text-align: center;
  padding: 15px;
}

.second-header .nav-link, .second-header a{
  
  color: #394248;;
}

.second-header .nav-link:hover, .second-header .nav-link:focus, .second-header a:hover,.second-header a:focus{
  text-decoration: underline;
  
  color: #394248;;
}

.list-group{
  list-style: none;
}

.list-item {
  padding: 5px 15px;
  border: 1px solid rgba(255,255,255,0.3);
  margin: 3px;
  background: #fff;
}

.list-item:nth-last-child(2), .list-item:nth-last-child(1){
  background: none;
  border: none;
}

.multi-sub-holder{
  width: 100%;
}
.sub-menu-holder {
display: flex;
color: #fff;
padding: 15px;
width: 100%;
text-align: center;
justify-content: center;
}
.sub-menu-holder a {
color: #fff;
font-size: 13.3333px !important;
width: max-content;
padding: 0 15px;
}

.sub-menu-holder a:hover {
color: #F4B6D1;
}


header.main-header .navigation.sticky {
  width: 100%;
  position: fixed !important;
  left: 0;
  top: 0;
  z-index: 9999;
}

header.main-header:hover .navigation, header.main-header:focus .navigation{
  background:rgb(0 64 114 / 90%);
}

.logoImg{
  min-width: 15vw;
}

.MainSlider{
  position: relative;
  
  z-index: 0;
}
.container{
  position: relative;
}
.LoginScreen {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #ffffff;
  z-index: 8;
  padding-top: 90px;
}

.LoginScreen .container{
  position: relative;
}
.item.mt-30.p-4:hover {
  background: rgba(255,255,255,0.1);
}
.LoginScreen .container .closebtn{
  
  position: fixed;
  
  right: 18px;
  top: 99px;
  text-decoration: none;
  cursor: pointer;
  color: #fff;
  font-size: 27px;
  padding: 0px 10px;
  border-radius: 15px;
  background: #394248;;
}
.LoginScreen .container .closebtn:hover{
  
  border-radius: 0px;
  color: #fff;
}
#UserLogin{
  display: flex;
  height: 100%;
  padding: 90px 0;
  overflow: auto;
}

.navbar-light .navbar-nav .nav-link {
  color: rgb(255 255 255);
  font-weight: 400;
}


.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
  color: rgb(255 255 255 / 70%);
}

.navbar-light .navbar-nav .nav-link.nav-btn{
  margin: 0% 10px;
  font-weight: 500;
  color: var(--themeclr1);
}

.player-wrapper {
  width: 100%;
  position: relative;
  height: 100vh;
}

.player-wrapper.demo::after{
  
  content: " Video Placeholder for Museam OR 360 View";
  text-align: center;
  display: flex;
  flex: 1;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  color: rgba(255,255,255,0.5);
  top: 0;
  background: rgba(0,0,0,0.3);
  font-size: 5vw;
}

.player-wrapper .video {
  width: 100%;
  height: 100%;
}

section{
  padding: 1rem;
}

.section-heading, .box-heading
{
  color: #a47831;
  padding: 0 0 30px 0;
  margin: 126px 0 0 0;
}

.book-menu{
  padding: 30px 0;
}

.text-theme-1{
  color: var(--themeclr1) !important;
}
.text-theme-2{
  color: var(--themeclr2) !important;
}
.bg-theme-1{
  background-color: var(--themeclr1) !important;
  color: #ffffff;
}
.bg-theme-2{
  background-color: var(--themeclr2) !important; color: #ffffff;
}
.btn-theme-1{
  background-color: var(--themeclr1); border: 1px solid var(--themeclr1); color: #ffffff;
}
.btn-theme-1:hover{
  color: var(--themeclr1);
  background-color: #ffffff;
}
.btn-theme-2{
  background-color: var(--themeclr2) !important; color: #ffffff;
}
.bg-grey{
  background-color: #e9e9e9;
}
.heading{
  color: var(--themeclr1);
}
.booking-content{
  display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: .6rem;
}
.w70{
  max-width: 70%;
}
.section-heading span, .box-heading span{
  position: relative;
}
.section-heading span::after{
  content: " ";
    position: absolute;
    width: 50%;
    top: 150%;
    right: 25%;
    height: 2px;
    left: 25%;
    margin: auto;
    background-color: #394248;
}
.box-heading span::after{
  content: " ";
    position: absolute;
    width: 30%;
    height: 1px;
    top: 150%;
    left: 0%;
    background-color: var(--themeclr2);
}

.socialshare ul {
  list-style: none;
  display: flex;
  padding: 0;
  justify-content: space-around;
  text-align: center;
  color: var(--themeclr1);
}
.socialshare ul li{
  cursor: pointer;
}
.reachby{
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.reachby li{
  position: relative;
  padding: 1rem;
  background-color: #e9e9e9;
}
.rb-title .heading{
  padding-top: 6px;
  float: left;
}
.rb-title span{
  padding-top: 6px;
  float: right;
}
span.rt-icon {
  background: #2699fb;
  padding: 0.5rem;
  color: #fff;
  position: absolute;
  border-radius: 50%;
  left: 10px;
}

footer{
  background-color: var(--themeclr1);
  color: #ffffff;
}
footer .footer-content{
  padding: 3rem 1rem 3rem 1rem;
}
footer a{
  color: #ffffff;
  text-decoration: underline;
}
footer a:hover{
  color: #e7d46b;
  text-decoration: none;
}
footer .heading{
  margin-bottom: 40px;
}
footer .heading i{
 color: #e7d46b;
}
footer .heading span{
  color: #e7d46b;
  padding: 1rem 0;
  border-bottom: 1px solid #ffffff;
}
footer .navmenu{
  padding: 0;
  list-style: none;
}

.timeline.containerx {
  
  transform: perspective(1000px) rotateY(9deg) scale(0.9) rotateX(44deg) rotateZ(352deg);
  overflow:hidden;
  max-height: 720px;
  transition: 0.6s ease all;
  background: rgb(255 255 255 / 36%);
  margin: 0;
  position: relative; 
  max-width: 520px;
  
  max-width: 540px;
  border-radius: 15px;
  margin-top: 100px;
  margin-bottom: 100px;
  box-shadow: 2px 5px 20px rgba(119, 119, 119, 0.2);
  cursor: default;
}

.timeline.containerx.view {
  overflow: visible;
  max-height: 3600px;
  margin-top: 10%;
  transform: none;
  transition: 0.6s ease all;
}
.timeline .leftbox {
  top: -5%;
  left: 5%;
  position: absolute;
  height: 110%;
  background-color: #e7d46b;
  box-shadow: 3px 3px 15px rgba(119, 119, 119, 0.5);
}

.timeline nav {
  margin: 2.6em auto;
  position: sticky;
  top: 90px;
}

.timeline nav a {
  list-style: none;
  padding: 35px;
  color: #394248;;
  font-size: 1.1em;
  display: block;
  transition: all 0.5s ease-in-out;
}

.timeline .leftbox h3 {
  color: #fff;
  margin: 15px 0;
  padding: 0 5px 0 45px;
  border-bottom: 3px solid;
  max-width: 270px;
}

.timeline .rightbox h3 {
  color: #fff;
  margin: 15px 0;
  padding: 0 5px 0 45px;
  border-bottom: 3px solid;
}

.timeline .rightbox {
  
  margin: 0 0 0 36px;

}

.timeline .rb-container {
  font-family: "PT Sans", sans-serif; 
  margin: 0 0 0 306px;
  margin: auto;
  display: block;
  position: relative;
}

.timeline .rb-container ul.rb {
  margin: 2.5em 0;
  padding: 0;
  display: inline-block;
}

.timeline .rb-container ul.rb > li {
  list-style: none;
  margin: auto;
  border-left: 1px dashed #fff;
  padding: 0 0 50px 30px;
  position: relative;
  cursor: pointer;
}




.timeline .rb-container ul.rb > li:last-child {
  border-left: 0;
}

.timeline .rb-container ul.rb > li::before {
  position: absolute;
 
  left: -15px;
  top: -1px;
  content: " ";
  border: 5px solid rgba(255, 255, 255, 1);
  border-radius: 500%;
  background: #e7d46b;
  height: 27px;
  width: 27px;
  transition: all 500ms ease-in-out;
}

.timeline .rb-container ul.rb > li:hover::before {
  border-color: #394248;;
  transition: all 1000ms ease-in-out;
}

.timeline ul.rb > li .timestamp {
  color: #a47831;
  position: relative;
  font-size: 24px;
}

.timeline .rb-container ul.rb > li .rb-content {
  padding: 15px;
  background: #fff;
  border-radius: 15px;
  top: -18px;
  position: relative;
}

.timeline .rb-container ul.rb > li:hover .rb-content{
  background-color: rgb(0 64 113 / 90%);
  border: 3px solid #fff;
  transition: 1s linear;
}

.timeline .rb-container ul.rb > li .rb-content:before {
  
  transition: 1s linear;
  position: absolute;
  width: 15px;
  left: -7px;
  top: 23px;
  transform: rotate(317deg);
  height: 15px;
}

.timeline .rb-container ul.rb > li:hover .rb-content:before {
content: " ";
background: #fff;
background: none;
transition: 1s linear;
}
.timeline .rb-content .item-title {
  color: #394248;;
}
.timeline .rb-container ul.rb > li:hover .rb-content .item-title {
  color: #fff;
  transition: 1s linear;
}
.timeline .container-3 {
  width: 5em;
  vertical-align: right;
  white-space: nowrap;
  position: absolute;
}

.timeline .container-3 input#search {
  width: 150px;
  height: 30px;
  background: #fbfbfb;
  border: none;
  font-size: 10pt;
  color: #262626;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  margin: 0.9em 0 0 28.5em;
  box-shadow: 3px 3px 15px rgba(119, 119, 119, 0.5);
}

.timeline .container-3 .icon {
  margin: 1.3em 3em 0 31.5em;
  position: absolute;
  width: 150px;
  height: 30px;
  z-index: 1;
  color: #4f5b66;
}

.timeline .extended-content {
  position: absolute;
  display: none;
  border-radius: 15px;
  margin: 0 45px;
  width: 100%;
  max-width: 290px;
  background: #fff;
  left: 100%;
  top: -18px;
  padding: 15px;
}

.timeline .form-check {
  padding-left: 2.5rem;
}
.timeline span.input-group-text {
  color: #394248;;
}
.timeline input::placeholder {
  padding: 5em 5em 1em 1em;
  color: #394248;;
}

@media screen and (min-width:1600px) {
  html,body{
    font-size: .9vw;
  }
  .logoImg{
    min-width: auto;
    max-width: 100%;
  }
  .react-datepicker.customPicker .react-datepicker__day {
    min-width: 3vw;
    min-height: 3vw;
    font-size: 1rem;
  }
  header.main-header .navigation{
    font-size: .8vw;
  }
}
@media screen and (max-width: 1024px) {
  .timeline.containerx, .timeline.containerx.view {

    margin-bottom: 0;
    margin-top: 100px;
  }
  .timeline.containerx {
  overflow:visible;
  max-height: fit-content;
    transform: perspective(1665px) rotateY(0) scale(1) rotateX(0);
    transition: 0.6s ease all;
  }
  .timeline .leftbox {
    display: none;
  }
  .timeline .rightbox {
      float: left;
      margin: 0 0 0 18px;
  }
  
  .timeline .rb-container ul.rb, .timeline .rightbox h3{
    width: 91%;
  }
  .logoImg{
    min-width: 200px;
    max-width: 100%;
  }
  .w70{
    max-width: 100%;
  }
  .navbar-light .navbar-nav .nav-link.nav-btn {
    margin: 10px 0;
  }
  .navbar-light .navbar-toggler {
      color: rgb(255 255 255 / 100%);
      border-color: rgb(255 255 255 / 100%);
  }
  .timeline .extended-content{
    margin:20px 0;
    
    left: 0;
    position: relative;
  }
  .timeline .rb-container ul.rb, .timeline .rightbox h3 {
      width: 100%;
  }
}


@media screen and (max-width: 767px) {
  
  
  .player-wrapper{
    height: 40vh;
  }
  .verification{
    position: absolute;
    top: 100px;
  }
  
  .veriFy{
    display: block;
    margin: auto;
    width: 100%;
    padding: 0 calc(25% - 18px);
  }

  #cmobile{
    width: 100%;
    border-radius: 5px;
    margin-top: 15px;
  }
  
}
@media screen and (max-width: 444px) {
  .react-datepicker.customPicker .react-datepicker__day {
    min-width: 2vw;
    min-height: 2vh;
  }
  .timeline .rb-container ul.rb, .timeline .rightbox h3 {
      width: 72vw;
  }
}
.only-print{
  display: none;
}
@media print {
  body {
    print-color-adjust: exact !important;
    -webkit-print-color-adjust: exact !important;
  }
  .only-print{
    display: block;
  }
  header, footer, ul.list-inline, #footer{
    display: none;
  }
  .section-heading, .box-heading{
    margin-top: 0;
  }
  .ticket-heading{
    background-color: #394248;;
  }
  .do-not-print{
    display: none !important;
  }
  .person{
    word-break: break-all;
  }
  .col-md-1,.col-md-2,.col-md-3,.col-md-4,
  .col-md-5,.col-md-6,.col-md-7,.col-md-8, 
  .col-md-9,.col-md-10,.col-md-11,.col-md-12 {
    float: left;
  }

  .col-md-1 {
    width: 8%;
  }
  .col-md-2 {
    width: 16%;
  }
  .col-md-3 {
    width: 25%;
  }
  .col-md-4 {
    width: 33%;
  }
  .col-md-5 {
    width: 42%;
  }
  .col-md-6 {
    width: 50%;
  }
  .col-md-7 {
    width: 58%;
  }
  .col-md-8 {
    width: 66%;
  }
  .col-md-9 {
    width: 75%;
  }
  .col-md-10 {
    width: 83%;
  }
  .col-md-11 {
    width: 92%;
  }
  .col-md-12 {
    width: 100%;
  }
}
